<!--
 * @Author: lzh
 * @Date: 2022-07-05 16:37:23
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 10:39:36
 * @Description: file content
-->
<template>
  <div class="edit-data-map">
    <div class="cell-b">
      <selfCell title="選擇圖片">
        <selfUpload
          v-model="configs.img"
          type="default"
          @change="setValue('img')"
        />
      </selfCell>
      <selfCell title="輸入圖片描述">
        <el-input
          v-model="configs.alt"
          type="textarea"
          @change="setValue('alt')"
          placeholder="輸入圖片描述"
          rows="3"
        />
      </selfCell>
      <selfCell title="選擇背景圖片">
        <selfUpload
          v-model="configs.bgImg"
          type="default"
          @change="setValue('bgImg')"
        />
      </selfCell>
      <h3>項-標題配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>

      <h3>項-描述配置
        <selfTips :content="$tipText.textWrap" />
      </h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入描述-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: 'edit-data-map',
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          img: '',
          bgImg: '',
        };
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
};
</script>

<style lang="less" scoped>
.edit-data-map {
}
</style>
